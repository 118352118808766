import MasonryComponent from '@wix/thunderbolt-elements/src/components/Masonry/viewer/Masonry';
import MasonryController from '@wix/thunderbolt-elements/src/components/Masonry/viewer/Masonry.controller';


const Masonry = {
  component: MasonryComponent,
  controller: MasonryController
};


export const components = {
  ['Masonry']: Masonry
};

